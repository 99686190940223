<template>
  <div v-show="messageBox.isShow">
     <el-dialog
        :visible.sync="showBox"
        :close-on-click-modal="false"
        :show-close="false"
        :modal="false"
        width="640px"
        center
      >
       <setting ref="settingEl" @close="closePanel" @openNetwork="openNetwork" :isMeeting="isMeeting" :isShow="messageBox.isShow" :activeDefaultIndex="settingPanelDefaultIndex"></setting>
      </el-dialog>
  </div>
</template>
<script>
import Setting from "@/components/setting/Setting.vue";
import { mapGetters } from 'vuex';
export default {
  components: { Setting },
  data() {
    return {
      messageBox: {
         isShow: false
      },
      showBox: true
    }
  },
  props: {
    isMeeting: { default : false },
  },
  computed: {
    ...mapGetters(["isShowSettingPanel","settingPanelDefaultIndex"])
  },
  watch: {
     isShowSettingPanel(value) {
       this.messageBox.isShow = value
     }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    closePanel() {
      this.messageBox.isShow = false
      this.$store.commit("isShowSettingPanel", false); 
      this.$store.commit("settingPanelDefaultIndex", 0); 
    },
    openNetwork() {
      this.$emit('openNetwork')
    }
  }
}
</script> 
<style lang="scss" scoped>
  ::v-deep .el-dialog--center .el-dialog__body {
        padding: 0;
      }
   ::v-deep .el-dialog__header {
      padding: 0;
   }
</style>