<template>
  <div class="setting-container">
    <div class="setting-header">
      <span class="setting-title">{{$t('setUp.seeting')}}</span>
      <my-icon class="setting-close" :iconName="'iconxitonglan_guanbi_hei'" :fontSize="16" @click="close"></my-icon>
    </div>
    <div class="setting-body">
      <div class="setting-nav" :class="$i18n.locale === 'en-US' ? 'seeting_width' :''">

        <ul class="setting-menu">
          <!-- <li class="setting-item" @click="handleNav(item, index)" :class="[index===activeIndex ? 'active' : '']"
              v-for="(item, index) in navList" :key="item.id" v-show="(item.id !== 6 && item.id !== 8) || (item.id === 8 && isMeeting) || (item.id === 6 && !isMeeting)">{{ item.name }}
          </li> -->
          <li class="setting-item" @click="handleNav(item, index)" :class="[index===activeIndex ? 'active' : '']"
              v-for="(item, index) in navList" :key="item.id" >{{ item.name }}
          </li>
        </ul>
      </div>

      <div class="setting-content">

        <div class="setting-normal" v-show="activeType==='normal'">
       <el-checkbox v-model="showDuration" @change="onCheckedNormalSetting(3)">{{$t('setUp.showTime')}}</el-checkbox>
          <div class="common-set-line">
          <el-checkbox style='margin-right:24px' v-model="frames" @change="onCheckedNormalSetting(4)">{{$t('setUp.limit')}}</el-checkbox>
            <el-select @change="frameChange" v-model="currentSelectedFrame" :disabled="!frames" class="userMinSelect setting-frame" size="mini">
              <el-option
                v-for="item in frameList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>

            <span class="unit">{{$t('setUp.second')}}</span>
          </div>

          <!-- 语言设置 -->
          <div class="common-set-line lang" v-show="!isMeeting">
            <div class="lang-text">{{$t('setUp.language')}}</div>

            <Lang :is-default="true" class="lang-select-box"/>
          </div>
        </div>

        <!-- 设置网络检测-->
        <div class="setting-network" v-show="activeType==='network'">
          <div class="network-test" v-if="!showNetworkResult">
            <ul>
              <li class="network-icon">
                 <my-icon :iconName="'iconshezhi_wangluojiance_64_lv'" :fontSize="64"></my-icon>
              </li>
              <li class="network-title">{{$t('setUp.netDetection')}}<span v-if="currentNetworkProgressValue">（{{currentNetworkProgressValue + '/' + networkProgressValue}}）</span></li>
              <li class="network-subtitle">{{currentNetworkProgressTip || $t('setUp.pleaseDec')}}</li>
              <li class="network-button">
                <el-button @click="startNetworkDetection" :loading="networkLoading">{{!networkLoading ? $t('setUp.start') : ''}}</el-button>
              </li>
            </ul>
          </div>
          <div class="network-result" v-if="showNetworkResult">
            <div class="network-result-header">
              <div class="status-icon">
                <my-icon :iconName="statusIconClassName" :fontSize="48"></my-icon>
              </div>
              <div class="status-content">
                <div class="status-title">{{$t('setUp.networkStatus')}}{{statusTitle}}</div>
                <div class="status-sub-title">{{$t('setUp.netOk')}}{{statusTitle}}</div>
              </div>
              <div class="status-button">
                <el-button @click="startNetworkDetection" :loading="networkLoading">{{!networkLoading ? $t('setUp.againDec') : ''}}</el-button>
              </div> 
            </div>
            <div class="network-result-status">
              <ul>
                <!-- <li class="result-item">
                  <div class="result-name">网络权限</div>
                  <div class="result-status">正常</div>
                </li> -->
                <li class="result-item">
                  <div class="result-name">{{$t('setUp.netConnection')}}</div>
                  <div class="result-status" :class="{ 'error' : localNetworkTime <= 0 }">{{localNetworkTime > 0 ? $t('setUp.ok') : $t('setUp.overTime')}}</div>
                </li>
                <li class="result-item">
                  <div class="result-name">{{$t('setUp.serverConnection')}}</div>
                  <div class="result-status" :class="{ 'error' : serverNetworkTime <= 0 }">{{serverNetworkTime > 0 ? $t('setUp.ok') : $t('setUp.overTime')}}</div>
                </li>
                <!-- <li class="result-item">
                  <div class="result-name">域名解析耗时</div>
                  <div class="result-status">正常</div>
                </li> -->
                <li class="result-item">
                  <div class="result-name">{{$t('setUp.connectionTime')}}</div>
                  <div class="result-status" :class="{ 'error' : serverNetworkTime <= 0 || serverNetworkTime >= 300 }">{{serverNetworkTime > 0 ? serverNetworkTime + 'ms' :  $t('setUp.overTime')}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <!-- 质量检测 -->
        <div class="setting-quality" v-show="isMeeting && activeType==='quality'">
           <div class="panel">
              <div class="title">{{$t('meeting.nets')}}<div class="desc">{{$t('meeting.delay')}} {{ userStats.rtt }}ms</div></div>
              <div class="body">
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.bandwidth')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.sentBitrate }}kb/s</span>
                   </div>
                   <div class="item bottom">
                      <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                      <span class="after">{{ userStats.recvBitrate }}kb/s</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.packet')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.sentLossRate }}%</span>
                   </div>
                   <div class="item bottom">
                      <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                      <span class="after">{{ userStats.recvLossRate }}%</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item" v-show="userStats.networkType">
                    <span class="before">{{$t('meeting.nets')}}</span> 
                    <span class="after">{{ userStats.networkType }}</span>
                   </div>
                </div>
              </div>
           </div>

           <div class="panel">
              <div class="title">{{$t('setUp.audio')}}</div>
              <div class="body">
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.bitrate')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.audioSentBitrate }}kb/s</span>
                   </div>
                   <div class="item bottom">
                      <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                      <span class="after">{{ userStats.audioRecvBitrate }}kb/s</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('setUp.microphone')}}</span>
                    <span class="after">{{ userStats.micDb }}dB</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('setUp.speaker')}}</span> 
                    <span class="after">{{ userStats.speakerDb }}dB</span>
                   </div>
                </div>
              </div>
           </div>

           <div class="panel">
              <div class="title">{{$t('setUp.video')}}</div>
              <div class="body">
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.Resolution')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.videoSentWidth }}x{{ userStats.videoSentHeight }}</span>
                   </div>
                   <div class="item bottom">
                    <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                    <span class="after">{{ userStats.videoRecvWidth }}x{{ userStats.videoRecvHeight }}</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.frameRate')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.videoSentFps }}{{$t('meeting.Frame')}}</span>
                   </div>
                   <div class="item bottom">
                      <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                      <span class="after">{{ userStats.videoRecvFps }}{{$t('meeting.Frame')}}</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.bitrate')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.videoSentBitrate }}kb/s</span>
                   </div>
                   <div class="item bottom">
                      <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                      <span class="after">{{ userStats.videoRecvBitrate }}kb/s</span>
                   </div>
                </div>
              </div>
           </div>

           <div class="panel">
              <div class="title">{{$t('meeting.Sharing')}}</div>
              <div class="body">
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.Resolution')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.shareSentWidth }}x{{ userStats.shareSentHeight }}</span>
                   </div>
                   <div class="item bottom">
                    <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                    <span class="after">{{ userStats.shareRecvWidth }}x{{ userStats.shareRecvHeight }}</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.frameRate')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.shareSentFps }}{{$t('meeting.Frame')}}</span>
                   </div>
                   <div class="item bottom">
                      <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                      <span class="after">{{ userStats.shareRecvFps }}{{$t('meeting.Frame')}}</span>
                   </div>
                </div>
                <div class="box">
                   <div class="item">
                    <span class="before">{{$t('meeting.frameRate')}}</span>
                    <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"
                    ></my-icon>
                    <span class="after">{{ userStats.shareSentBitrate }}kb/s</span>
                   </div>
                   <div class="item bottom">
                      <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"
                      ></my-icon>
                      <span class="after">{{ userStats.shareRecvBitrate }}kb/s</span>
                   </div>
                </div>
              </div>
           </div>

           <div class="btn-area">
              <el-button type="primary" @click="openNetworkWin">{{$t('setUp.netDetection')}}</el-button>
           </div>
        </div>

         <!-- 反馈 -->
        <div class="setting-feedback" v-show="activeType==='feedback'">
          <div class="feedback-title">{{$t('setUp.submitFeedback')}}</div>
          <div class="feedback-contact">
            <span class="feedback-contact-title">{{$t('setUp.contactInfo')}}</span>
          </div>
          <div class="feedback-button">
            <div class="telephone">{{$t('setUp.call')}}</div>
            <div class="submit">
              <el-button >{{$t('setUp.submitFeedback')}}</el-button>
            </div>
          </div>
        </div>

         <!-- 设置截图 -->
        <div class="setting-screenshot" v-show="activeType==='screenshot'"></div>

         <!-- 设置视频 -->
        <div class="setting-video" v-show="activeType==='video'">
            <my-video id="settingVideo" :isShow="currentVideoDevice && currentVideoDevice.deviceId && activeType==='video'" :width="416" :height="234" class="video-view">
           </my-video>
            <p class="note">{{$t('setUp.cameraOK')}}</p>
            <div class="camera">
              <span class="title">{{$t('setUp.camera')}}</span>
              <el-select @change="videoChange" value-key="deviceId" v-model="currentVideoDevice" class="userSelect" size="mini">
                <el-option
                  v-for="item in videoList"
                  :key="item.deviceId"
                  :label="item.deviceName"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <p class="my-video-title">{{$t('setUp.myVideo')}}</p>
            <!-- <div class="my-video-checkbox">
              <my-checkbox rowCount="3" @click="onCheckedVideoImage" :parentName="'myVideoImage'" :collection="myVideoImage.collection"
                       class="setting-normal-item"></my-checkbox>
            </div> -->
            <div class="my-video-checkbox">
              <el-checkbox :disabled="!deviceResolution720" :title="!deviceResolution720?'您的电脑硬件暂不支持该功能':''"  v-model="myVideoQuality" @change="onCheckedVideoQuality">{{$t('setUp.highVideo')}}</el-checkbox>
            </div>
            <!-- <div class="my-video-checkbox">
              <my-checkbox rowCount="3" :parentName="'myVideoSpeed'" :collection="myVideoSpeed.collection"
                     class="setting-normal-item"></my-checkbox>
            </div> -->
            <div class="radio-box">
              <el-radio-group @change="onCheckedVideoResolution" v-model="videoResolution" v-show="myVideoQuality">
                <el-radio :label="720" class="radio-item"  :title="!deviceResolution1080?'您的电脑硬件暂不支持该功能':''" :disabled="!deviceResolution720">{{$t('720p')}}</el-radio>
                <el-radio :label="1080" class="radio-item" :title="!deviceResolution1080?'您的电脑硬件暂不支持该功能':''" :disabled="!deviceResolution1080">{{$t('1080p（对网络要求较高）')}}</el-radio>
              </el-radio-group>
            </div>
            <div class="code-selection">
              视频编码选择
            </div>
            <div class="radio-box">
              <el-radio-group @change="onCheckedVideoCodeModule"  v-model="videoCodeModule">
                <el-radio :label="1" class="radio-item">{{$t('AV1（对电脑配置要求较高）')}}</el-radio>
                <el-radio :label="0" class="radio-item">{{$t('H.264')}}</el-radio>
              </el-radio-group>
            </div>
        </div>

        <!-- 设置音频 -->
        <div class="setting-audio" v-show="activeType==='audio'">
          <div class="audio">
            <div class="micro">
              <span class="title">{{$t('setUp.microphone')}}</span>

              <el-select @change="audioChange" value-key="deviceId" v-model="currentMicDevice" class="userSelect" size="mini">
                <el-option
                  v-for="item in audioList"
                  :key="item.deviceId"
                  :label="item.deviceName"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="audio-volume">
              <span class="title">{{$t('setUp.voice')}}</span>

              <div class="slider-box">
                <el-slider 
                  class="slider" 
                  @change="audioVolumeChange" 
                  input-size="mini" 
                  :min="0" 
                  :max="100" 
                  v-model="audioVolumeValue"
                ></el-slider>

                <div class="slider-text">{{ audioVolumeValue }}</div>
              </div>
 
            </div>
            <div class="audio-status">
              <div class="box">
                 <div class="status-show">
                    <progressBar :percent="currentHuaTongPercent"></progressBar>
                 </div>
                 <div class="status-tips">{{$t('setUp.microphoneOk')}}</div>
              </div>
            </div>
          </div>
          <div class="audio">
            <div class="micro">
              <span class="title">{{$t('setUp.speaker')}}</span>
              <div class="micro-right">
                <el-select @change="speakChange" value-key="deviceId" v-model="currentSpeakerDevice" class="userSelect" size="mini">
                  <el-option
                    v-for="item in speakerList"
                    :key="item.deviceId"
                    :label="item.deviceName"
                    :value="item">
                  </el-option>
                </el-select>

                 <el-button 
                    class="speaker-button" 
                    type="primary" 
                    size="mini"
                    :title="beginTestSpeaker ? $t('setUp.stopVoice') : $t('setUp.addSpeack') "  
                    @click="onTestMySpeaker"
                  >{{beginTestSpeaker ? $t('setUp.stopDetection') : $t('setUp.testLoudspeaker')}}</el-button>

              </div>
            </div>
            <div class="audio-volume">
              <span class="title">{{$t('setUp.voice')}}</span>

              <div class="slider-box">
                <el-slider 
                  class="slider" 
                  @change="speakerVolumeChange" 
                  input-size="mini" 
                  :min="0" 
                  :max="100" 
                  v-model="speakerVolumeValue"
                ></el-slider>

                <div class="slider-text">{{ speakerVolumeValue }}</div>
              </div>
            </div>
            <div class="audio-status">
              <div class="box">
                 <div class="status-tips">{{ beginTestSpeaker ? $t('setUp.voiceOk') : ''}}</div>
              </div>
            </div>
             <!-- 音频 测试扬声器 -->
             <audio ref="playoutAudio" class="testPlayoutVolume media-reset-flag" loop src="~@/assets/speaker_test_sound.wav"></audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalDeviceInfo, setLocalDeviceInfo } from "@/utils/device"
import { ping } from "@/utils/network"
import { signalUrl } from '@/config/index';
import { NETWORK_TEST_URL, MAX_SHARE_VIDEO_FPS } from "@/constant/index";
import Lang from "@/components/lang/index";
import progressBar from "@/components/progressBar/index";
import myVideo from "@/components/video/index";
import { loganLog } from "@/utils/log"


export default {
  name: 'Setting',
  components: {
    Lang,
    progressBar,
    myVideo
  },
  data() {
    return {
      frameList: [1, 2, 4, 6, 8, 10, 15], //帧/秒
      currentSelectedFrame: MAX_SHARE_VIDEO_FPS,
      activeIndex: 0,
      activeType: 'normal',

      showDuration: false,
      frames: false,
      myVideoQuality: false,

      videoResolution: 720, // 0 720p 、 1 1080p
      videoCodeModule: 0, // 0 默认H.264、1 av1 
      deviceResolution720 : false,
      deviceResolution1080 : false,

      // myVideoImage: {checked: [], collection: [{id: 1, name: this.$t('setUp.useVideo')}]},
      // myVideoSpeed: {checked: [], collection: [{id: 1, name: this.$t('setUp.useHardware')}]},
     
      currentHuaTongPercent: 0,
      currentYangShengQiPercent: 0,
      beginTestSpeaker: false, //是否开始测试扬声器

      networkProgressValue: 3,
      currentNetworkProgressValue: 0,
      currentNetworkProgressTip: '',
      showNetworkResult: false,
      networkLoading: false,
      localNetworkTime: 0,
      serverNetworkTime: 0,
      statusIconClassName: '',
      statusTitle: '',
      micTestVolumeInterval: null,

      userAudioStats: [], // 音频信息
      userVideoStats: [], // 视频信息
      userShareStats: [], // 共享信息
      
      userStats: { //汇总
         rtt: 0, //网络延迟
         sentBitrate: 0, //带宽上行
         recvBitrate: 0, //带宽下行
         sentLossRate: 0, //上行丢包率
         recvLossRate: 0, //下行丢包率
         networkType: '', //网络类型：wifi 有线

         audioSentBitrate: 0, //音频上行码率
         audioRecvBitrate: 0, //音频下行码率
         micDb: 0, //麦克风DB
         speakerDb: 0, //扬声器DB

         videoSentHeight: 0, //视频上行分辨率
         videoSentWidth: 0,
         videoRecvHeight: 0, //视频下行分辨率
         videoRecvWidth: 0,
         videoSentFps: 0, //视频上行帧率
         videoRecvFps: 0, //视频下行帧率
         videoSentBitrate: 0, //视频上行码率
         videoRecvBitrate: 0, //视频下行码率

         shareSentHeight: 0, //屏幕共享上行分辨率
         shareSentWidth: 0,
         shareRecvHeight: 0, //屏幕共享下行分辨率
         shareRecvWidth: 0,
         shareSentFps: 0, //屏幕共享下行帧率
         shareRecvFps: 0, //屏幕共享下行帧率
         shareSentBitrate: 0, //屏幕共享下行码率
         shareRecvBitrate: 0, //屏幕共享下行码率
      },

      audioVolumeValue: 0, 
      speakerVolumeValue: 0,
      currentMicDevice: null,
      currentSpeakerDevice: null,
      currentVideoDevice: null 
    }
  },
  props: {
     activeDefaultIndex: { default : 0 },
     isShow: { default : true }, 
     isMeeting: { default : false }
  },
  watch: {
    activeDefaultIndex(value) {
       this.activeIndex = value
       const navType = this.navList[value].type
       
       this.activeType = navType
       if (this.activeType === "network") {
         this.startNetworkDetection()
       }
    },
    isShow(value) { 
      if (value) {
         this.initSetting(this.activeType)
      } else {
         //停用麦克风测试
         this.destroyMicroTest()
      }

      if (!value && this.$deviceControl) {
          this.$deviceControl.stopLocalCamera()
      }
    },
    activeType(value) {
      if (value === 'audio' || value === 'video') {
          if(!this.$deviceControl) return
          this.$deviceControl.initDevicePermission()
       }

       this.initSetting(value)

       if (value !== 'video' && this.$deviceControl) {
          this.$deviceControl.stopLocalCamera(true)
       }
    },
    currentNetworkProgressValue(value) {
      if (value <= this.networkProgressValue && value > 0) {
          const tips = [this.$t('setUp.netConnection'), this.$t('setUp.serverConnection'), this.$t('setUp.connectionTime')]
          this.currentNetworkProgressTip = this.$t('setUp.detecting') + `${tips[value - 1]}`
      }
      if (value === this.networkProgressValue) {
          this.showNetworkResult = true
          this.networkLoading = false
      }
    },
    serverNetworkTime(value) {
       if (value < 0 || this.localNetworkTime <= 0) { //-1 超时
         this.statusIconClassName = 'iconwangluojiance_wuwang_48_hong'
         this.statusTitle = this.$t('setUp.abnormal')
       } else if (value > 0 && value < 100) { //正常 良好
         this.statusIconClassName = 'iconwangluojiance_wancheng_48_lv'
         this.statusTitle = this.$t('setUp.ok')
       } else if (value >= 100 && value < 300) { //较差
         this.statusIconClassName = 'iconwangluojiance_jiaocha_48_cheng'
         this.statusTitle =  this.$t('setUp.poor')
       } else if (value >= 300) { //极差
         this.statusIconClassName = 'iconwangluojiance_jiaocha_48_cheng'
         this.statusTitle =  this.$t('setUp.range')
       }
    },

    audioVolumeValue(value) {
      this.$store.commit('device/setAudioVolumeValue', value)
    },
    audioVolumeState: {
      immediate: true,
      handler(value) {
        this.audioVolumeValue = value
      }
    },

    currentMicDevice(value) {
      this.$store.commit('device/setCurrentMicDevice', value)
    },
    audioDeviceState: {
      immediate: true,
      handler(value) {
        this.currentMicDevice = value
      }
    },

    speakerVolumeValue(value) {
      this.$store.commit('device/setSpeakerVolumeValue', value)
    },
    speakerVolumeState: {
      immediate: true,
      handler(value) {
        this.speakerVolumeValue = value
      }
    },

    currentSpeakerDevice(value) {
      this.$store.commit('device/setCurrentSpeakerDevice', value)
    },
    speakerDeviceState: {
      immediate: true,
      handler(value) {
        this.currentSpeakerDevice = value
      }
    }, 

    async currentVideoDevice(value,oldValue) {
      // 设备发生变化要再取分辨率
      if(value && value.deviceId && (!oldValue || value.deviceId !== oldValue.deviceId)){
        this.$store.commit('device/setCurrentCameraDevice', value)
        let deviceResolution = await this.getLocalResolution(value.deviceId)
        
        this.deviceResolution720 = false
        this.deviceResolution1080 = false
        // console.error(this.$sensors)
        // const aaa = this.$roomClient.getRemoteMediaStats()
        deviceResolution && deviceResolution.forEach(element => {
          if(element === 720){
            this.deviceResolution720 = true
          }else if(element === 1080){
            this.deviceResolution1080 = true
          }
        });

        if(!this.deviceResolution1080){
          this.videoResolution =  720
          this.onCheckedVideoResolution()
        }

        console.error('getRemoteMediaStats:',deviceResolution)
        if(!this.deviceResolution720){
          this.videoResolution = 0
          this.myVideoQuality = false
          this.onCheckedVideoQuality()
        }else{
          this.videoResolution = 720
          this.myVideoQuality = true
          this.onCheckedVideoQuality()
        }

        //设备发生变化以后更新摄像头设备
        if(oldValue){
          this.startSettingVideoView(value.deviceId)
          this.$deviceControl.setCurrentCameraDevice(value.deviceId, this.isMeeting)
        }
      }

    },
    videoDeviceState: {
      immediate: true,
      handler(value) {
        this.currentVideoDevice = value
      }
    }
  },
  computed: {
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },
    isUseYangShengQi() {
      return this.selfInfo.isUseYangShengQi
    },
    isUseHuaTong() {
      return this.selfInfo.isUseHuaTong
    },
    audioList() {
      return this.$store.state.device.micDeviceList
    },
    audioVolumeState() {
      return this.$store.state.device.audioVolumeValue
    },
    audioDeviceState() {
      return this.$store.state.device.currentMicDevice
    },
    speakerList() {
      return this.$store.state.device.speakerDeviceList
    },
    speakerVolumeState() {
      return this.$store.state.device.speakerVolumeValue
    },
    speakerDeviceState() {
      return this.$store.state.device.currentSpeakerDevice
    },
    videoList() {
      return this.$store.state.device.cameraDeviceList
    },
    videoDeviceState() {
      return this.$store.state.device.currentCameraDevice
    },
    navList(){
      return [
      {
          id: 1,
          type: 'normal',
          name: this.$t('setUp.normal')
        },
        {
          id: 2,
          type: 'audio',
          name: this.$t('setUp.audio')
        },
        {
          id: 3,
          type: 'video',
          name: this.$t('setUp.video')
        },
        // {
        //   id: 4,
        //   type: 'record',
        //   name: '录制'
        // },
        // {
        //   id: 5,
        //   type: 'screenshot',
        //   name: '截图'
        // },
        {
          id: 6,
          type: 'network',
          name: this.$t('setUp.netDetection')
        },
        // {
        //   id: 7,
        //   type: 'feedback',
        //   name: '反馈'
        // }, 
        // {
        //   id: 8,
        //   type: 'quality',
        //   name: '质量检测'
        // },
        ]
    },
  },
  beforeDestroy() {
     this.destroyMicroTest()
     if (this.$deviceControl) {
        this.$deviceControl.stopLocalCamera()
     }
  },
  methods: {
    speakChange(e) {
      this.$deviceControl.setCurrentSpeakerDevice(e.deviceId)
    },
    async getLocalResolution(deviceId){
      let deviceResolution = getLocalDeviceInfo()['resolution'+deviceId] || ''
      if(deviceResolution){
        deviceResolution = JSON.parse(deviceResolution)
      }else{
        deviceResolution = await this.$deviceControl.getDeviceResolution(deviceId)
        console.error('获取分辨率',deviceId)
        console.error(deviceResolution)
        setLocalDeviceInfo({
        ['resolution'+deviceId]: JSON.stringify(deviceResolution), 
        })
      }
      return deviceResolution
    },
    speakerVolumeChange(e) {
      // 将音量值存储到本地
      setLocalDeviceInfo({
        speakerValue: this.speakerVolumeValue
      })
      
      if(Number.isInteger(this.speakerVolumeValue)) {
         this.$refs['playoutAudio'].volume = 0.01 * this.speakerVolumeValue
      }
      
      //会中设置逻辑 音量即时生效
      if (this.isMeeting && this.isUseYangShengQi) {
         const audioEl = document.querySelectorAll(`.audioPlayoutVolumeItem`)
         this.$meetingControl.setAudioPlayoutVolume(this.speakerVolumeValue, audioEl)
         const selfId = this.$configs.peerId
        loganLog(`面板设置扬声器音量：${this.speakerVolumeValue}`)
        //  if(this.speakerVolumeValue === 0 && selfId) { //音量为0 关闭扬声器
        //     // update vuex isUseYangShengQi
        //     this.$store.commit('member/updateUser', {
        //       userId: selfId,
        //       isUseYangShengQi: false
        //     })
        //  }
      }
    },

    audioChange(e) { 
      this.$deviceControl.setCurrentMicDevice(e.deviceId, this.isMeeting)
    },
    audioVolumeChange(e) {
      // 将音量值存储到本地
      setLocalDeviceInfo({
        audioValue: this.audioVolumeValue
      })

      //会中设置逻辑 音量即时生效
      if (this.isMeeting && this.isUseHuaTong) {
        this.$roomClient.setAudioCaptureVolume(this.audioVolumeValue)
        const selfId = this.$configs.peerId
        loganLog(`面板设置麦克风音量：${this.audioVolumeValue}`)

        //  if(this.audioVolumeValue === 0 && selfId) { //音量为0 关闭麦克风
        //     // update vuex isUseHuaTong
        //     this.$store.commit('member/updateUser', {
        //       userId: selfId,
        //       isUseHuaTong: false
        //     })
        //  }
      }
    },
    videoChange(e) {
      // 放到监听当前id变化后 
      // this.startSettingVideoView(e.deviceId)
      // this.$deviceControl.setCurrentCameraDevice(e.deviceId, this.isMeeting)
      if (this.$deviceControl) {
        this.$deviceControl.stopLocalCamera()
      }
    },
    frameChange(e) {
      setLocalDeviceInfo({
        shareLimitValue: e
      })
    },
    initSetting(value) {
      //初始化常规数据
      if (value === 'normal') {
        this.loadNormalSetting()
      }
      //初始化视频数据
      if (value === 'video') {
        this.loadVideoSettng()
      } 
      //初始化音频数据
      if (value === 'audio') {
        this.loadAudioSettng()
      } else {
        this.destroyMicroTest()
      }
      
      //初始化质量检测
      if (value === 'quality') {
        //this.getStats()
      }
    },
    handleNav(item, index) {
      this.activeIndex = index
      this.activeType = item.type
    },
    close() {
      this.$emit('close')
    },
    //常规设置
    loadNormalSetting() {
       const localDeviceData = getLocalDeviceInfo()
       //显示我的参会时长 首次默认选中 
       const defaultMark =  localDeviceData.joinDurationTag
      if (!defaultMark && !localDeviceData.joinDuration) {
          this.showDuration = true
          setLocalDeviceInfo({
            joinDurationTag: 1, 
            joinDuration: 1
          })
          this.$store.commit("meet/updateGlobalMeetState", {
           joinDuration: 1
          })
       } else if (localDeviceData.joinDuration > 0) {
        this.showDuration = true
       }
        //我的屏幕共享限制 以及时长 首次默认选中
        const defaultShareMark = localDeviceData.shareLimitTag
     
       if (!defaultShareMark && !localDeviceData.shareLimit) {
         this.frames = true
          setLocalDeviceInfo({
            shareLimitTag: 1, 
            shareLimit: 1
          })
       } else if (localDeviceData.shareLimit > 0) {
         this.frames = true
       }

      
       if (localDeviceData.hasOwnProperty('shareLimitValue')) {
          this.currentSelectedFrame = Number(localDeviceData.shareLimitValue)
       } else {
          setLocalDeviceInfo({
            shareLimitValue: MAX_SHARE_VIDEO_FPS //默认值
          })
       }
    },
    onCheckedNormalSetting(type) {
       if (type === 3) {
          setLocalDeviceInfo({
            joinDurationTag: 1,
            joinDuration: this.showDuration ? 1 : 0
          }) 
          this.$store.commit("meet/updateGlobalMeetState", {
           joinDuration: this.showDuration ? 1 : 0
          })
       }
       if (type === 4) {
          setLocalDeviceInfo({
            shareLimitTag: 1,
            shareLimit: this.frames ? 1 : 0
          })
       }
    },

    //视频设置
    async loadVideoSettng() {

        if (this.currentVideoDevice && this.currentVideoDevice.deviceId && this.isShow && this.activeType === 'video') {
          this.startSettingVideoView(this.currentVideoDevice.deviceId)
          console.error(7777)
          //画质
          const localDeviceData = getLocalDeviceInfo()
          this.myVideoQuality = localDeviceData.videoQuality ? true : false
          //分辨率
          this.videoResolution = localDeviceData.videoResolution ? localDeviceData.videoResolution : 720
          this.videoCodeModule = localDeviceData.videoCodeModule ? localDeviceData.videoCodeModule : 0
          
        } 

    },
   
    startSettingVideoView(selectValue) {
      if(this.isShow){
        console.error('setting startSettingVideoView')
        this.$deviceControl.startLocalCamera(416, 234, selectValue, 'settingVideo') 
      }
    },
    onCheckedVideoImage() {
      // TODO:
    },
    onCheckedVideoQuality() {
      setLocalDeviceInfo({
        videoQuality: this.myVideoQuality ? 1 : 0
      })
    },
    onCheckedVideoResolution() {
      setLocalDeviceInfo({
        videoResolution: this.videoResolution
      })
    },
    onCheckedVideoCodeModule() {
      setLocalDeviceInfo({
        videoCodeModule: this.videoCodeModule ? this.videoCodeModule  : 0
      })
    },
    //音频设置
    loadAudioSettng() {
        //麦克风设备
        //音量
        this.$deviceControl.initMicDeviceVolume(this.isMeeting && this.isUseHuaTong)
       
        //扬声器设备
        //音量
        this.$deviceControl.initPlayoutVolume(null, this.isMeeting && this.isUseYangShengQi)
        
        //麦克风测试
        this.$deviceControl.enableMicTest() 
          
        //检测麦克风音量值
        const that = this
        if (that.micTestVolumeInterval) {
          clearInterval(that.micTestVolumeInterval)
          this.micTestVolumeInterval = null
        }
        this.micTestVolumeInterval = setInterval(() => {
          const volume = that.$deviceControl.getMicTestVolume() 
          that.currentHuaTongPercent = volume.toFixed(2)
        }, 500)    
    },

    //网络检测
    async startNetworkDetection() {
       this.networkLoading = true
       // 1.网络连接情况
       this.currentNetworkProgressValue = 1
       const local = await ping(NETWORK_TEST_URL, 0.3)
       this.localNetworkTime = local.pingTime
       
       // 2.服务器连接 + 连接耗时 
       const server_url = 'https://' + signalUrl.replace('/signal-server','') + '/test/test.png' 
       this.currentNetworkProgressValue = 2 
       const server = await ping(server_url, 0.3)
       this.serverNetworkTime = server.pingTime
       setTimeout(() => {
           this.currentNetworkProgressValue = 3 
       },1000) 
    },

    //检测扬声器
    async onTestMySpeaker() {
      if (!this.beginTestSpeaker) {
        this.beginTestSpeaker = true

        if (this.currentSpeakerDevice && this.currentSpeakerDevice.deviceId) {
           await this.$refs['playoutAudio'].setSinkId(this.currentSpeakerDevice.deviceId)
        }
        if(Number.isInteger(this.speakerVolumeValue)) {
           this.$refs['playoutAudio'].volume = 0.01 * this.speakerVolumeValue
        }

        this.$refs['playoutAudio'].play();
      } else {
        this.$refs['playoutAudio'].pause()
        this.beginTestSpeaker = false
      }
    },
    destroyMicroTest() {
       if (this.micTestVolumeInterval) {
          clearInterval(this.micTestVolumeInterval)
          this.micTestVolumeInterval = null
       }
       if (this.$deviceControl) {
          this.$deviceControl.disableMicTest() 
       }
    },
    openNetworkWin() {
       this.$emit('openNetwork')
    },
    async getStats() {
      //TODO:质量检测
    },
  }
}
</script>

<style scoped lang="scss">
    ::v-deep .el-checkbox__label {
      font-size: 14px !important;
    }
    ::v-deep .el-checkbox,
    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        font-family: 'iconfont' !important;
        content: "\e6f2" !important;
    } 
    ::v-deep .el-button--default{
      height: 36px;
      padding:0 10px;
      font-size: 14px;
      color: #fff;
      background-color: #1ab370;
      border-radius: 4px;
      &:hover{
        background: #3BBF82;
      }
      &:focus{
        background: #0E8C5A;
      }
    }

  .radio-box {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 14px;
    .radio-item {
      display: flex;
      align-items: center;
      height: 24px;
      font-size: 12px;
      font-weight: normal;
      color:#000000
    }
  }
  
.common-set-line {
  display: flex;
  align-items: center;

  &.lang {
    margin-top: 6px;

    .lang-text {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    .lang-select {
      width: 100px;
    }
    .lang-select-box {
      width: 100px;
    }
  }
}

.slider-box {
  display: flex;
  align-items: center;
  
  width: 321px;

  .slider {
    flex-grow: 1;
  }

  .slider-text {
    // width: 50px;
    // background: red;
    margin-left: 10px;
    font-size: 14px;
  }
}

.userSelect {
  width: 320px;
}
.userMinSelect {
  width: 90px;
  margin-left: -8px;
  vertical-align: top;
}

.setting-container {
  width: 640px;
  height: 520px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;

  .setting-header {
    text-align: center;
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    box-sizing: border-box;

    .setting-title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
    }

    .setting-close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }

  .setting-body {
    display: flex;
    height: calc(100% - 48px);
    box-sizing: border-box;
    .setting-nav {
      width: 128px;
      box-sizing: border-box;
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      padding-top: 16px;
      transition: all .2s ease;

      .setting-menu {
        .setting-item {
          box-sizing: border-box;
          padding: 8px 0 8px 24px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, 0.06);
          }
        }

        .setting-item.active {
          color: #FFFFFF;
          background: #1ab370;
        }
      }
    }
    .seeting_width{
      width:142px;
    }

    .setting-content {
      flex: 1 1 auto;
      box-sizing: border-box;
      overflow: scroll;
      .setting-normal {
        padding: 24px 0 0 48px;
        .setting-normal-item {
          overflow: hidden;
          margin-top: 8px;

          ::v-deep .input__word {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }

          &:first-child {
            margin-top: 0;
          }
        }

        .setting-frame-unit {
          display: inline-block;
        }

        .setting-frame {
          display: inline-block;
        }

        .unit {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 38px;
          margin-left: 16px;
          vertical-align: top;
        }
      }

      .setting-feedback {
        padding: 32px 48px 0 48px;

        .feedback-title {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        .feedback-contact {
          background: rgba(255, 255, 255, 0.6);
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          margin-top: 18px;
          padding: 0px 0 0px 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .feedback-contact-title {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }

          .contact-form {
            ::v-deep .input-box-border {
              border: none;
            }
          }
        }

        .feedback-button {
          display: flex;
          justify-content: space-between;

          .telephone {
            margin-top: 46px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }

          .submit {
            margin-top: 38px;
          }
        }
      }

      .setting-network {
        position: relative;
        box-sizing: border-box;
        height: 100%;

        .network-test {
          padding-top: 80px;
          text-align: center;

          .network-icon {
            width: 64px;
            height: 64px;
            display: inline-block;
          }

          .network-title {
            font-size: 20px;
            margin-top: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
          }

          .network-subtitle {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            margin-top: 6px;
          }

          .network-button {
            margin-top: 18px;
            /* ::v-deep button {
                height: 36px;
                padding:0 10px;
            }
            ::v-deep .el-icon-loading {
               font-size: 16px;
            } */
          }
        }

        .network-result {
          box-sizing: border-box;
          padding: 0 24px;

          .status-button {
            ::v-deep button {
                padding:0 10px;
                height: 36px;
            }
            ::v-deep .el-icon-loading {
               font-size: 16px;
            }
          }

          .network-result-header {
            display: flex;
            box-sizing: border-box;
            padding: 24px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.04);

            .status-icon {
              width: 48px;
              height: 48px;
            }

            .status-content {
              flex: 1 1 auto;
              box-sizing: border-box;
              padding-left: 10px;

              .status-title {
                font-size: 16px;
                font-weight: 400;
                color: #000000;
                line-height: 18px;
              }

              .status-sub-title {
                margin-top: 6px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }
            }
          }

          .network-result-status {
            padding-top: 30px;
            box-sizing: border-box;

            .result-item {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;

              .result-name {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
              }

              .result-status {
                font-size: 14px;
                font-weight: 400;
                color: #1AB370;
                line-height: 20px;
              }
              .error{
                color: #FF4D4F;
              }

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }

      .setting-quality {
        position: relative;
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .panel {
            width: 472px;
            height: 104px;
            border-radius: 4px;
            border: 1px solid #E5E5E5;
            margin-top: 11px;
            margin-left: 16px;
        }
        .panel:nth-child(-n+2) .body {
           padding-left: 29px;
        }
        .panel:nth-child(n+3) .body {
           padding-left: 16px;
        }
        .panel .item.bottom {
           margin-left: 36px;
        }
        .panel:nth-child(n+3) .box:nth-child(1) .item.bottom, 
        .panel:nth-child(1) .box:nth-child(2) .item.bottom
        {
           margin-left: 49px;
        }

        .body {
           display: flex;
           padding-top: 12px;
           justify-content:space-between;
           width: 100%;
        }
        .box {
           width: 33.3%;
           .item {
             width: 100%;
             height: 18px;
             line-height: 18px;
             font-size: 13px;
             font-weight: 400;
             display: flex;
             .before {
                color: #999999;
                margin-right: 10px;
             }
             .after {
                color: #333333;
                margin-left: 1px;
             }
           }
           .item.bottom {
             margin-top: 4px;
           }
        }
        .title {
            height: 30px;
            background: #F7F7F7;
            padding-left:14px;
            position: relative;
            line-height: 30px;
           
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            .desc {
               position: absolute;
               right: 10px;
               top: 0;
               font-size: 12px;
               color: #333333;
            }
        }
        .btn-area {
           width: 100%;
           height: 80px;
           position: absolute;
           margin-top: 30px;
           border-top: 1px solid #E5E5E5;
           button {
             position: absolute;
             top: 24px;
             right: 24px;
           }
        }
      }

      .setting-audio {
        padding: 0 48px;
        .audio {
          padding: 24px 0;
          border-bottom: 1px solid #E5E5E5;
          .micro {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title{
              font-size: 14px;
              font-weight: 400;
              color: #000000;
              line-height: 20px;
            }
            .micro-right {
              display: flex;
              justify-content: space-between;
              width: 320px;
              align-items: center;
            }
          }
          .audio-volume {
            margin-top: 22px;
            display: flex;
            justify-content: space-between;
            .title {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
            .volume-slider {
              margin-left: 60px;
            }
          }
          .audio-status {
            display: flex;
            justify-content: flex-end;
            .box {
               width: 320px;
               margin-top: 32px;
            }
            .status-show {
               margin-bottom: 12px;
            }
            .status-tips {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
            }
          }
          &:last-child {
            border-bottom: none;
          }
          ::v-deep .speaker-button {
            /* width: 100px; */
            height: 36px;
            padding: 0 10px;
            margin-left: 10px;
          }
        }
      }
      
      ::v-deep .selectInput .contentText, ::v-deep .selectInput .list .item {
           font-size: 13px;
           font-family: PingFangSC-Regular, PingFang SC;
           font-weight: 400;
           color: #333333;
      }
      .setting-video {
        padding: 0 48px;
        .note {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          margin-top: 8px;
        }
        .video-view {
          width: 416px;
          height: 234px;
          margin-top: 16px;
        }
        .camera {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 18px;
            .title {
              font-size: 14px;
              font-weight: 400;
              color: #000000;
              line-height: 20px;
            }

        }
        .my-video-title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-top: 8px;
        }
        .code-selection{
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        .my-video-checkbox {
          width: 100%;
          margin-top: 8px;

          .setting-normal-item {
             overflow: hidden;
             margin-top: 8px;

            ::v-deep .input__word {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
        
      }
    }
  }
}
</style>